.playerWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  &:not(.isPostDetail) {
    &:not(.fullscreenVideo) {
      video {
        height: 376px;
        min-height: 376px;
      }
    }
  }
  &:not(.fullscreenVideo) {
    video {
      max-height: 50vh;
    }
  }
  .playerOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    padding: 16px;
    .remainingFreeTime {
      position: absolute;
      border-radius: 6px;
      align-items: center;
      width: fit-content;
      left: 50%;
      transform: translate(-50%, -50%);
      bottom: -25px;
      color: #fff;
      font-size: 12px;
      flex-direction: column;
      font-weight: 600;
      line-height: 18px;
      .time {
        color: #ff0505;
        font-size: 13px;
        margin-right: 4px;
      }
      display: flex;
      background: rgba(0, 0, 0, 0.66);
      padding: 8px 10px;
      button {
        margin-top: 8px;
        font-size: 12px;
        margin-left: 4px;
      }
    }
    .mediaControlBtn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .pauseBtn {
        display: none !important;
      }
      .skipBtn {
        margin: 0px 20px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.1);
      }
      .centerBtn {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 5px;
      }
      .replayWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(0, 0, 0, 0.1);
        .replayText {
          font-weight: 600;
          font-size: 16px;
          color: #ffffff;
        }
      }
      .playWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .leftCorner {
      display: flex;
      width: calc(100% - 215px);
      align-items: flex-end;
      &.mobileCtrlLeftCorner {
        width: calc(100% - 115px);
      }
      .playTime {
        font-weight: 600;
        font-size: 12px;
        color: #ffffff;
        background: rgba(0, 0, 0, 0.66);
        border-radius: 5px;
        padding: 4px 6px;
      }
      .timebar {
        display: flex;
        align-items: center;
        margin-left: 12px;
        background: rgba(0, 0, 0, 0.66);
        border-radius: 5px;
        height: 26px;
        flex: 1;
        padding-left: 10px;
        padding-right: 10px;
      }
      .sliderTime {
        -webkit-appearance: none;
        cursor: pointer;
        height: 5px;
        margin: 0;
        vertical-align: bottom;
        width: 100%;
      }

      .sliderTime::-webkit-slider-thumb {
        -webkit-appearance: none;
        background: #aeaeae;
        border-radius: 8px;
        box-shadow: inset 0 0 0 5px #eaeaea;
        height: 10px;
        width: 10px;
      }
    }
    .rightCorner {
      margin-left: auto;
      display: flex;
      align-items: flex-end;
      .speedCtrl {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.66);
        border-radius: 5px;
        padding: 5px 0;
        margin: 0px 3px;
        font-size: 12px;
        line-height: 12px;
        flex-shrink: 0;
        width: 60px;
        height: 28px;
        font-weight: 600;
        position: relative;
        &:hover, &:active {
          .speedCtrlOptions {
            display: block;
          }
        }
        .speedBtn {
          flex-shrink: 0;
          color: #ffffff;
        }
        .speedCtrlOptions {
          display: none;
          position: absolute;
          bottom: 25px;
          border-radius: 2px;
          max-height: 250px;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 5px;
          }
          
          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: var(--green-clr);
            box-shadow: 0 0 1px rgba(255, 255, 255, .5);
          }    
          background: rgba(0, 0, 0, 0.66);
          box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.1);
          // border: 1px solid #ccc;
        }
      }
      .volumeCtrl {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(0, 0, 0, 0.66);
        border-radius: 5px;
        margin: 0px 3px;
        padding: 1px 0;
        &:hover, &:active {
          .volumeSlider {
            opacity: 1;
            height: 60px;
            margin-top: 14px;
            margin-bottom: 5px;
          }
        }
        :global(.MuiSlider-root) {
          color: #ffffff;
        }
        :global(.MuiSlider-thumb) {
          box-shadow: none;
        }
        .speakerBtn {
          flex-shrink: 0;
        }
        .volumeSlider {
          margin: 0;
          opacity: 0;
          height: 0;
          transition: height 0.2s ease-out;
        }
      }
      .fullscreenBtn {
        background: rgba(0, 0, 0, 0.66);
        border-radius: 5px;
        font-weight: 600;
        font-size: 12px;
        color: #ffffff;
        flex-shrink: 0;
        padding: 5px 6px;
        display: flex;
        align-items: center;
        padding: 5.5px;
        margin-left: 3px;
        .fullscreenText {
          margin-left: 4px;
        }
      }
    }
    &.notPostDetail {
      .fullscreenBtn, .timebar, .skipBtn, .speedCtrl {
        display: none !important;
      }
      .rightCorner {
        pointer-events: none;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .playerWrapper {
    &:not(.isPostDetail) {
      &:not(.fullscreenVideo) {
        video {
          height: unset;
          min-height: unset;
        }
      }  
    }
  }
}

@media screen and (min-width: 768px) {
  .playerWrapper .playerOverlay {
    &:not(.fullscreen) {
      .leftCorner {
        display: none;
      }
      .rightCorner {
        display: none;
      }
    }
    &:not(.fullscreen):hover, &:not(.fullscreen):active {
      background: rgba(0, 0, 0, 0.1);
      .mediaControlBtn {
        .pauseBtn {
          display: flex !important;
        }
      }
      .leftCorner {
        display: flex;
      }
      .rightCorner {
        display: flex;
      }
    }
  }
}
