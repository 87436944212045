.textEditorWrapper {
  cursor: text;
}

.mention,
.hashtag {
  color: #0abc71;
  &:hover {
    color: #0abc71;
  }
  cursor: pointer;
}

.link {
  color: #0000ee;
  cursor: pointer;
  &:hover {
    color: #0000ee;
    text-decoration: underline;
    cursor: pointer;
  }
}

.overLimit {
  color: #fa553f;
}

.policy {
  // border-bottom: 3px double #fa553f;
  position: relative;
  color: #fa553f;
  .description {
    position: absolute;
    display: none;
    top: 12px;
    left: -20px;
    width: 240px;
    z-index: 10000;
    font-size: 11px;
    line-height: 18px;
    background: transparent;
    * {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
      cursor: default;
    }
    .content {
      color: #FFFFFF;
      width: 100%;
      background: #000;
      margin-top: 10px;
      padding: 10px;
      border-radius: 8px;
    }
    a {
      color: var(--green-clr);
      text-decoration: underline;
      cursor: pointer !important;
    }
  }
  &:hover .description, &:active .description {
    display: block;
  }
}
