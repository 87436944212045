.richContentWrapper {
  white-space: pre-line;
  * {
    display: inline;
  }
}

.mention,
.hashtag {
  color: #0abc71;
  &:hover, &:focus {
    color: #0abc71;
  }
  cursor: pointer;
}

.hashtag {
  display: inline-block;
}

.email {
  color: #0000ee;
  cursor: pointer;
  &:hover, &:focus {
    color: #0000ee;
  }
}
.special {
  background: #ffff00;
  color: #000000;
  padding: 3px;
}

.link {
  color: #0000ee;
  display: inline !important;
  cursor: pointer;
  &:hover, &:focus {
    color: #0000ee;
    text-decoration: underline;
    display: inline !important;
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
  .richContentWrapper {
    font-size: 14px;
    line-height: 32px;
  }
}
