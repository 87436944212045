.mediaHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #000;
  position: relative;
  border-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 1px solid #ccc;
  &.isPostDetail {
    margin-left: -24px;
    margin-right: -24px;
    border-radius: 0;
    .mediaWrapper {
      border-radius: 0;
    }
  }
  &:not(.isPostDetail) {
    .mediaWrapper {
      &.isVideo {
        height: 376px;
        display: flex;
        align-items: center;
        justify-content: center;  
      }
      &.imageMedia, &.linkMedia {
        max-height: 376px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .mediaWrapper {
    width: 100%;
    border-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;  
    overflow: hidden;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    a {
      display: block;
    }
    .processingLabel {
      position: absolute;
      bottom: 15px;
      left: 15px;
      color: #fff;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 6px 8px;
      background: var(--green-clr);
      .text {
        margin-left: 5px;
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
      }
    }

    .liveStreamThumbnail {
      .streamInfo {
        position: absolute;
        top: 15px;
        right: 15px;
        display: flex;
        .streamLabel {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          color: #fff;
          border-radius: 5px;
          padding: 8px 10px;
          background: red;
          animation: blinker 2s linear infinite;
        }
        .viewerCount {
          margin-left: 10px;
          color: #fff;
          border-radius: 4px;
          display: flex;
          align-items: center;
          padding: 8px 10px;
          span {
            font-size: 12px;
            line-height: 18px;
            margin-left: 8px;
            font-weight: 600;
          }
          background: rgba(0, 0, 0, 0.66);
        }  
      }
      .beforeLiveLabel {
        position: absolute;
        bottom: 15px;
        width: fit-content;
        left: 15px;
        color: #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 6px 8px;
        background: red;
        .timeWrapper {
          display: flex;
          margin-left: 6px;
          flex-direction: column;
          .text {
            margin-bottom: 4px;
            text-align: left;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
          }
          .time {
            font-size: 14px;
            text-align: left;
            font-weight: 500;
            line-height: 21px;
          }
        }
      }
    }
    &.scaleMaxHeight {
      max-width: 30vh;
      max-height: 42vh;
    }
    .mediaControlBtn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background: rgb(0 0 0 / 59%);
      width: 120px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 10px;  
    }
    &.imageMedia {
      .lockedOverlayWrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.4) 100%
        );
      }
    }
    &.linkMedia {
      .mediaContainer {
        display: flex;
        align-items: flex-end;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: inline-block;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 50%,
            rgba(0, 0, 0, 0.66) 83.83%
          );
        }
        .media {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
        .linkInfo {
          z-index: 1;
          position: absolute;
          bottom: 16px;
          left: 16px;
          right: 16px;
          .linkTitle {
            font-weight: 600;
            font-size: 16px;
            color: #ffffff;
            text-align: left;
            overflow: hidden;
            text-overflow: initial;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            white-space: pre-line;        
          }
          .bottomLeft {
            display: flex;
            font-size: 12px;
            .linkSrc {
              color: #ffffff;
              margin-right: 16px;
            }
            .timeSince {
              color: #cccccc;
            }
          }
        }
      }
      &.adsPost .mediaContainer {
        .linkInfo {
          bottom: 12px;
          .linkTitle {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            overflow: hidden;
            -webkit-box-orient: vertical;
            white-space: pre-line;    
          }
        }
        &::after {
          background: none;
        }
      }
    }
    &.linkMediaNoThumbnail {
      padding: 1px;
      .mediaContainer {
        position: unset;
        display: flex;
        flex-direction: column;
        width: 100%;
        &::after {
          background: #f3f3f3;
          width: 100%;
        }
        .linkInfo {
          position: unset;
          padding: 16px 8px;
          width: 100%;
          .linkTitle {
            color: #000000;
          }
          .bottomLeft .linkSrc {
            color: #000000;
          }
        }
      }
    }
    .media {
      width: 100%;
      outline: none;
      .imageWrapper {
        -webkit-touch-callout: none !important;
        user-select: none !important;
        pointer-events: none !important;
        position: relative;
        width: 100%;
        height: 100%;    
      }
      &.blurred {
        .imageWrapper {
          filter: blur(4px);
          -webkit-filter: blur(4px);
        }
      }
      .lockImage {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.4) 100% );
        border-radius: 6px;
        padding: 10px;
      }  
    }
  }
}

.beforeLiveWrapper {
  color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  background: red;
  .timeWrapper {
    display: flex;
    margin-left: 6px;
    flex-direction: column;
    .text {
      font-weight: 700;
      font-size: 16px;
      text-align: left;
      line-height: 24px;
    }
    .time {
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      line-height: 24px;
    }
  }
}

.notifyBtn {
  margin-left: 20px;
  button {
    width: fit-content;
    height: 40px !important;
    font-size: 11px;
    border-radius: 12px;
    color: #000 !important;
    background: #fff !important;
    padding: 4px 10px !important;
  }
}

@media screen and (max-width: 767px) {
  .mediaHeader {
    &.isPostDetail {
      margin-bottom: 8px;
      margin-left: -8px;
      margin-right: -8px;  
    }
    .mediaWrapper.adsPost .mediaContainer .linkInfo {
      bottom: 8px !important;
    }
    &:not(.isPostDetail) {
      .mediaWrapper {
        &.isVideo {
          height: unset;
        }
        &.imageMedia, &.linkMedia {
          max-height: 50vh;
        }
      }
    }  
  }
}

@keyframes blinker {
  50% {
    opacity: 0.4;
  }
}
