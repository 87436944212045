.dialogWrapper {
  background: rgba(0, 0, 0, 0.9);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .closeBtn {
    position: absolute;
    top: 16px;
    cursor: pointer;
    right: 16px;
  }
  .image {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}
