.text {
  display: flex;
  height: 33px;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  width: 90px;
  justify-content: flex-start;
  .content {
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    color: #ffffff;
    white-space: nowrap;
  }
  &.isWhite .content {
    color: var(--green-clr) !important;
  }
  .icon {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    padding-top: 1px;
    margin-left: auto;
  }
  &.isMembership {
    width: unset;
    justify-content: center;
  }
  &.isVip {
    .content {
      opacity: 0.4;
    }
  }
  &:hover {
    opacity: 0.8;
  }
}
