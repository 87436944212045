.ring {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    // temp fix since font-size doesn't go below 12px somehow
    font-size: 10px;
    transform: scale(0.67);
    white-space: nowrap;
    &.overflow {
      color: #e54040;
    }
  }
  circle {
    transition: stroke-dashoffset 0.35s;
  }
}
