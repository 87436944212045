.button {
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  background: #0abc71;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .main {
    display: flex;
    justify-content: center;
    align-items: center;  
  }
  .subText {
    font-weight: 600;
    font-size: 10px;
    text-align: center;
    color: #FFFFFF;
  }
  &.rounded {
    border-radius: 23px;
  }
  &.disabled {
    opacity: 0.33;
    cursor: not-allowed;
  }
  &:hover:not(.disabled) {
    opacity: 0.85;
  }
  &.alternateColor {
    background-color: #fdb62b;
  }
  &.alternateColorFaded {
    background-color: #cccccc;
  }
  &.alternateColorWarning {
    background-color: #fa553f;
  }
  &.darkOrange {
    background-color: #FD6F1F;
  }
  &.pink {
    background-color: #F432A7;
  }
  &.lightRed {
    background-color: var(--light-red-clr);
  }
  &.hasIcon {
    display: flex;
    align-items: center;
    position: relative;
    // TODO: custom style for icon when need
    .icon {
      position: relative;
      margin-right: 3px;
    }
  }
  &.blackAndWhite {
    color: #000000;
    border: 1.5px solid #000000;
    background-color: transparent;
    &.loading {
      background-color: #000000;
    }
    &:hover:not(.disabled) {
      opacity: 1;
      color: #ffffff;
      background-color: #000000;
    }
  }
  &.faded {
    background-color: #cccccc;
  }
  &.fullWidth {
    width: 100%;
  }
  &.fadingStyle {
    color: #666666;
    border: 1px solid #cccccc;
  }
  &.bigGuyStyle {
    border-radius: 12px;
    align-items: center;
    height: 50px;
    &.hasSubText {
      height: 60px;
    }
  }
}

@media screen and (max-width: 767px) {
  .button {
    font-size: 12px;
  }
  .button.hasSubText {
    height: 60px !important;
    .subText {
      font-size: 9px;
    }
  }
}

@media screen and (max-width: 400px) {
  .button {
    font-size: 9px;
  }
  .button.hasSubText {
    padding-bottom: 3px;
    height: 60px !important;
    .subText {
      font-size: 8px;
    }
  }
}
