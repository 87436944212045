.playerWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  // &:not(.isPostDetail) {
  //   &:not(.fullscreenVideo) {
  //     video {
  //       height: 376px;
  //       min-height: 376px;
  //     }
  //   }  
  // }
  &:not(.fullscreenVideo) {
    video {
      max-height: 50vh;
    }
  }
  .playerOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    padding: 16px;
    .streamInfo {
      position: absolute;
      top: 15px;
      right: 15px;
      display: flex;
    }
    .buffering {
      position: absolute;
      top: 15px;
      left: 15px;
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.66);
    }
    .streamLabel {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: #fff;
      border-radius: 5px;
      padding: 8px 10px;
      background: red;
      animation: blinker 2s linear infinite;
    }
    .remainingFreeTime {
      position: absolute;
      border-radius: 6px;
      align-items: center;
      width: fit-content;
      left: 50%;
      transform: translate(-50%, -50%);
      bottom: -25px;
      color: #fff;
      font-size: 12px;
      flex-direction: column;
      font-weight: 600;
      line-height: 18px;
      .time {
        color: #ff0505;
        font-size: 13px;
        margin-right: 4px;
      }
      display: flex;
      background: rgba(0, 0, 0, 0.66);
      padding: 8px 10px;
      button {
        margin-top: 8px;
        font-size: 12px;
        margin-left: 4px;
      }
    }
    .viewerCount {
      margin-left: 10px;
      color: #fff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      padding: 8px 10px;
      span {
        font-size: 12px;
        line-height: 18px;
        margin-left: 8px;
        font-weight: 600;
      }
      background: rgba(0, 0, 0, 0.66);
    }
    .mediaControlBtn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .pauseBtn {
        display: none !important;
      }
      .centerBtn {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 5px;
      }
      .replayWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(0, 0, 0, 0.1);
        .replayText {
          font-weight: 600;
          font-size: 16px;
          color: #ffffff;
        }
      }
      .playWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .leftCorner {
      display: flex;
      width: calc(100% - 220px);
      align-items: flex-end;
      &.mobileCtrlLeftCorner {
        width: calc(100% - 100px);
      }
      .playTime {
        font-weight: 600;
        font-size: 12px;
        color: #ffffff;
        background: rgba(0, 0, 0, 0.66);
        border-radius: 5px;
        padding: 4px 6px;
      }
    }
    .rightCorner {
      margin-left: auto;
      display: flex;
      align-items: flex-end;
      .volumeCtrl {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(0, 0, 0, 0.66);
        border-radius: 5px;
        margin: 0px 3px;
        padding: 1px 0;
        &:hover {
          .volumeSlider {
            opacity: 1;
            height: 60px;
            margin-top: 14px;
            margin-bottom: 5px;
          }
        }
        :global(.MuiSlider-root) {
          color: #ffffff;
        }
        :global(.MuiSlider-thumb) {
          box-shadow: none;
        }
        .speakerBtn {
          flex-shrink: 0;
        }
        .volumeSlider {
          margin: 0;
          opacity: 0;
          height: 0;
          transition: height 0.2s ease-out;
        }
      }
      .fullscreenBtn {
        background: rgba(0, 0, 0, 0.66);
        border-radius: 5px;
        font-weight: 600;
        font-size: 12px;
        color: #ffffff;
        padding: 5px 6px;
        display: flex;
        align-items: center;
        padding: 5.5px;
        margin-left: 3px;
        .fullscreenText {
          margin-left: 4px;
        }
      }
    }
    &.notPostDetail {
      .fullscreenBtn, .timebar, .speedCtrl {
        display: none !important;
      }
      .rightCorner {
        pointer-events: none;
      }
    }
    &.isLiveStreamDetail {
      .rightCorner {
        pointer-events: initial !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .playerWrapper {
    &:not(.isPostDetail) {
      &:not(.fullscreenVideo) {
        video {
          height: unset;
          min-height: unset;
        }
      }  
    }
  }
}

@media screen and (min-width: 768px) {
  .playerWrapper .playerOverlay {
    &:not(.fullscreen) {
      .leftCorner {
        display: none;
      }
      .rightCorner {
        display: none;
      }
    }
    &:not(.fullscreen):hover {
      background: rgba(0, 0, 0, 0.1);
      .mediaControlBtn {
        .pauseBtn {
          display: flex !important;
        }
      }
      .leftCorner {
        display: flex;
      }
      .rightCorner {
        display: flex;
      }
    }
  }
}

@keyframes blinker {
  50% {
    opacity: 0.4;
  }
}
